/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Felicity Joslin';
  const content = 'Chief People Officer and Executive General Manager, People and Culture';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Felicity Joslin'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/felicity-joslin.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
          Felicity joined Latitude Financial in August 2023 as Chief People Officer and Executive General Manager, People & Culture.<br /><br />

With over 20 years’ experience in academia and professional services, Felicity has worked in a variety of HR roles in public and private organisations across Australia and the UK. Prior to joining Latitude, Felicity led People & Culture teams in several digital businesses, including Wesfarmers’ OneDigital division, The Iconic and Afterpay, who were all at different stages of the “start-up” journey.


          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
          Felicity holds a Doctor of Psychology degree in Industrial & Organisational Psychology from The University of Melbourne. She is a registered psychologist and began her career as a consultant working for a boutique psychometric company. She spent 11 years at NAB where she led the People & Culture team for Consumer Banking, and previously, was the General Manager for Talent, Culture and Leadership. Throughout her career, Felicity has worked across a range of HR disciplines including organisational development, talent management and business partnering.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
